.headerWrapper {
	height: 50px;
}
.header {
	height: 50px !important;
	line-height: unset !important;
	padding: 0 !important;
    display: flex;
    align-items: center;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: #fff !important;
    border-bottom: 1px solid #d8dbe0;
	border: 0;
    box-shadow: 0 12px 23px 0 rgb(172 57 212 / 4%);
    -webkit-clip-path: inset(0 0 -1000px 0);
	clip-path: inset(0 0 -1000px 0);
	position: fixed;
	z-index: 100;
	justify-content: space-between;
    right: 0;
	left: 200px;
	transition: all 0.2s;
}
