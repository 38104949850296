// @import '@styles/style';

// .trigger {
//     font-size: 18px;
//     padding: 0 20px;
//     cursor: pointer;
//     transition: color 0.3s;
// }

// .trigger:hover {
//     color: $primary-color;
// }

// .logoName {
//     flex: 1 1;
//     overflow: hidden;

//     div {
//         font-size: 8px;
//     }
// }

// .logoCenter {
//     font-weight: 600;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 16px;
//     line-height: normal;

//     span {
//         margin-left: 5px;
//     }
// }

// .siteLayout {
//     transition: all 0.2s;
// }

// .root {
//     :global {
//         .ant-layout-sider-collapsed {
//             max-width: 50px !important;
//             min-width: 50px !important;
//             width: 50px !important;

//             .ant-menu-inline-collapsed {
//                 width: 50px;

//                 .ant-menu-item {
//                     text-align: center;
//                 }
//             }

//             :local(.logo) {
//                 justify-content: center;
//                 padding: 0;
//             }
//         }
//     }
// }

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // background: rgba(0, 0, 0, 0.42);
    z-index: 98;
    cursor: pointer;
}
.container {
    padding-top: 90px;
    margin: 20px;
    width: auto;
}