.header {
	height: 90px !important;
	line-height: unset !important;
	padding: 10px 40px !important;
    background: #fff !important;
    border-bottom: 1px solid #d8dbe0;
    box-shadow: 0 12px 23px 0 rgb(172 57 212 / 4%);
	transition: all 0.2s;
    display: grid;
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}
	}
	position: fixed;
	z-index: 100;
	width: -webkit-fill-available;
}
.headerRight {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: right;
}
.name {
	display: flex;
	align-items: center;
}

.fullName {
	flex: 1;
	padding-left: 10px;
	overflow: hidden;

	strong {
		font-weight: bold;
	}

	div {
		font-size: 12px;
	}
}