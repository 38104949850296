.custom-loader {
    --d:44px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  color: #109CF1;
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 2px,
    calc(0*var(--d))      calc(1*var(--d))     0 4px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 6px,
    calc(-1*var(--d))     calc(0*var(--d))     0 8px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 10px,
    calc(0*var(--d))      calc(-1*var(--d))    0 12px;
  animation: s7 1s infinite steps(8);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999999;
  }
  
  @keyframes s7 {
    100% {transform: rotate(1turn)}
  }

