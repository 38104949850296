.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .left {
        font-weight: 700;
        font-size: 15px;
        color: #334D6E;
    }
}
.table-operations {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}