
.sidebar {
    box-shadow: 0 2px 2px 0 rgba(60, 75, 100, .14), 0 3px 1px -2px rgba(60, 75, 100, .12), 0 1px 5px 0 rgba(60, 75, 100, .2);
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    // overflow-y: auto;
    .logo {
        height: 50px;
        // color: $black;
        font-weight: 600;
        display: flex;
        align-items: center;
        font-size: 18px;
        border-bottom: 1px solid #cccccc73;
        padding: 45px 10px;
        span {
            margin-right: 20px;
        }
    }
    .collapsed {
        // padding: 20px 0px 20px 25px;
        padding: 45px 0px 45px 20px;
    }
    
    // padding-left: 20px;
     // overflow: auto;
    // margin: 20px;

    :global {
        // .collapsed-menu {
        //     .ant-layout-sider-children {
        //         position: fixed;
        //         width: inhe;
        //     }
        // }
        .ant-layout-sider-children {
            position: fixed;
            width: inherit;
        }
        .ant-menu {
            padding: 10px 0;
            .ant-menu-item {
                height: 38px;
                line-height: 38px;
                color: #fff;
            }
            .ant-menu-item-group-title {
                color: #fff;
                font-weight: bold
            }
            &.ant-menu-root {
                overflow: hidden;
                overflow-y: auto;
                height: calc(100vh - 91px);
            }
        }
		.ant-menu-item:hover, .ant-menu-submenu-title:hover {
			// color: $accent-blue;
			img {
				 filter: invert(0%) sepia(14%) saturate(2336%) hue-rotate(332deg) brightness(105%) contrast(87%);
			}
		}
		.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
			// background-color: $white;
			img {
				 filter: invert(0%) sepia(14%) saturate(2336%) hue-rotate(332deg) brightness(105%) contrast(87%);
			}
		}
        .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-item-icon {
            img {
				 filter: invert(0%) sepia(14%) saturate(2336%) hue-rotate(332deg) brightness(105%) contrast(87%);
			}
        }
		.ant-menu-inline .ant-menu-item::after {
			content: none;
		}
        
    }
}
// .menuDropdown {
// 	width: 250px;

// 	.item {
// 		padding: 10px 30px;
// 		display: flex;
// 		align-items: center;

// 		svg {
// 			margin-right: 10px;
// 			font-size: 18px;
// 		}
// 	}
// }
.search {
	flex-direction: row-reverse;
	border: 0;
	width: 170px;
	padding-left: 0;

	span::before {
		display: none !important;
	}

	&:focus {
		border: 0;
		outline: none;
		box-shadow: none;
	}
}