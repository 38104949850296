.card {
    border-radius: 15px;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    :global {
        .ant-card-head {
            border:none;
            font-weight: 600;
            font-size: 18px;
        }
        .ant-card-body {
            padding-left: 40px;
            padding-top: 0;
        }
    }
}