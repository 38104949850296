.wrapper {
	height: 100vh;
	width: 100%;
	background-color: #001529;
	display: flex;
}
.left {
	height: 100vh;
	background-image: url('../../../public/login-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	flex: 2;
	position: relative;
	color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;

	.leftOverlay {
		background-color: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
	}

	.leftContent {
		padding: 20px;
		display: flex;
		position: relative;
		align-items: center;
		color: #eee;
		margin-bottom: 20%;
		width: 60%;
		animation: faced 2s ease;

		h1 {
			margin-top: -10px;
			color: #eee;
			font-size: 2.5em;
    		font-weight: 700;
			text-transform: uppercase;
  			text-rendering: optimizeLegibility;
		}
		p {
			color: #ffffff;
			font-size: 1.5em;
    		font-weight: 700;
			text-transform: uppercase;
  			text-rendering: optimizeLegibility;
		}
	}
	
}
.right {
	width: 40%;
	max-width: 500px;
	background-color: #fff;
	display: grid;
	align-items: center;
	
	.box {
		h2 {
			font-size: 1.5em;
    		font-weight: 700;
			text-transform: uppercase;
  			text-rendering: optimizeLegibility;
			text-align: center;
		}
		animation: faced 2s ease;
		padding: 50px;
	}
}

@keyframes faced {
	0% {
		bottom: -50px;
		opacity: 0;
	}
	100% {
		bottom: 0px;
		opacity: 1;
	}
}
