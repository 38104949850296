@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  font-family: Poppins,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@table-gray: #707683;
@gray: #90A0B7;
@dark-blue-text: #334D6E;
@primary-color: #109CF1;
@table-header-bg: #F2F2F2;
@card-head-color: @dark-blue-text;
@select-item-selected-bg: #F5F8FA;

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: @primary-color;
  color: #fff
0}
.ant-layout-footer {
  background-color: unset;
}
.ant-table-thead > tr > th {
  background: #fff;
}